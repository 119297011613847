



















































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import ImagePreviewDialogue from "@/vue/components/dialogues/ImagePreviewDialogue.vue";
import { ContractLine, IContractLine } from "@/model/ContractLine";
import { Country } from "@/model/Country";
import { ISaveResponse } from "@/model/ISaveResponse";
import { OrderItem } from "@/model/OrderItem";
import { IOrderItemOption, OrderItemOption } from "@/model/OrderItemOption";
import { Product } from "@/model/Product";
import { ProductVM, IProductVM } from "@/model/Api/VM/ProductVM";
import { ProductRange } from "@/model/ProductRange";
import { ProductType } from "@/model/ProductType";
import { ISizeOptionGroup, SizeOptionGroup } from "@/model/SizeOptionGroup";
import { SizeOption, ISizeOption } from "@/model/SizeOption";
import { Ref, Watch } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ImagePreviewDialogue
    }
})

export default class OrderProductDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("productForm") private readonly productForm!: VForm;
    @Ref("stockItemForm") private readonly stockItemForm!: VForm;
    @Ref("stockItemSizingForm") private readonly stockItemSizingForm!: VForm;
    @Ref("contractItemForm") private readonly contractItemForm!: VForm;

    private isEdit: boolean = false;
    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private contractLines: Array<ContractLine> = [];
    private countriesOfOrigin: Array<Country> = [];
    private products: Array<ProductVM> = [];
    private types: Array<ProductType> = [];
    private ranges: Array<ProductRange> = [];
    private sizeOptionGroups: Array<SizeOptionGroup> = [];
    private product: Product = new Product();
    private orderItem: OrderItem = new OrderItem();
    private orderItemOptions: Array<OrderItemOption> = [];

    orderID: number = 0;
    selectedTypeID: string = utils.emptyGuidValue;
    selectedRangeID: string = utils.emptyGuidValue;

    //
    // -- Watchers
    //

    @Watch("selectedTypeID")
    private async onSelectedTypeIDChanged() {
        if (this.selectedTypeID == null) {
            this.selectedTypeID = utils.emptyGuidValue;
            this.loadProducts();
        }

        if (this.selectedTypeID != utils.emptyGuidValue) {
            this.loadProducts();
        }
    }

    @Watch("selectedRangeID")
    private async onSelectedRangeIDChanged() {
        if (this.selectedRangeID == null) {
            this.selectedRangeID = utils.emptyGuidValue;
            this.loadProducts();
        }

        if (this.selectedRangeID != utils.emptyGuidValue) {
            this.loadProducts();
        }
    }

    @Watch("orderItem.productID")
    private async onOrderItemProductIDChanged() {
        if (this.orderItem.productID == utils.emptyGuidValue) {
            return;
        }
    
        if (this.orderItem.productID == null) {
            this.orderItem.productID = utils.emptyGuidValue;
            utils.resetObject(this.orderItem);
            this.orderItemOptions = [];
            return;
        } else if (!this.isEdit) {
            this.orderItem.orderID = this.orderID;
            this.products.forEach(p => {
                if (p.product.id == this.orderItem.productID) {
                    if (p.inUse && !p.product.allowDuplicateProduct) {
                        toastr.warning("This product is already in this order. Please edit the product quantities directly.");
                        this.product.imageID == p.product.imageID;
                        this.orderItemOptions = [];
                        return;
                    } else {
                        this.product = p.product;
    
                        if (p.product.requiresCoo) {
                            this.loadCoos();
                        }
    
                        // stock items without sizing
                        if (!p.product.isContract && !p.product.requiresNonContractSizing) {
                            this.orderItemOptions = [];
                            let option = new OrderItemOption();
                            option.orderQuantity = p.product.minOrderQuantity;
                            option.sizeSelected = true;
                            this.orderItemOptions.push(option);
                        }
    
                        // stock items with sizing options
                        else if (!p.product.isContract && p.product.requiresNonContractSizing) {
                            this.orderItemOptions = [];
                            this.loadSizeOptionGroups();
                        }
    
                        // contract sizing
                        else if (p.product.isContract) {
                            this.orderItemOptions = [];
                            this.setUpContractSizingInfo();
                        }
                    }
                }
            });
        } else if (this.isEdit) {
            this.orderItem.orderID = this.orderID;
            this.products.forEach(p => {
                if (p.product.id == this.orderItem.productID) {
                    this.product = p.product;
                }
            });
        }
    }

    //
    // -- methods
    //

    async open(orderID: number): Promise<void> {
        this.reset();
        this.isEdit = false;
        this.dialogueTitle = "Add Product";
        this.orderID = orderID;
        this.product.imageID = "00000000-0000-0000-0000-000000000000";

        await this.loadProductRange();
        await this.loadProductTypes();
        await this.loadProducts();

        this.showDialogue = true;
    }

    async edit(orderID: number, orderItemID: number): Promise<void> {
        this.isEdit = true;
        this.reset();
        this.orderID = orderID;

        await this.loadProducts();

        const response = await apiClient.get(`/api/order/loadOrderItem?ID=${orderItemID}`);
        this.orderItem.update(response.item);
        this.orderItemOptions.push(...response.options.map((o: IOrderItemOption) => new OrderItemOption(o)));
        this.product.update(response.product);

        if (this.product.isContract) {
            const response = await apiClient.get(`/api/size/loadGroupsByProduct?productID=${this.product.id}`);
            this.sizeOptionGroups.push(...response.map((g: ISizeOptionGroup) => new SizeOptionGroup(g)));
        }

        if (this.product.requiresCoo) {
            await this.loadCoos();
        }

        this.orderItemOptions.forEach(o => {
            o.sizeSelected = true;
        });
        
        this.dialogueTitle = "Edit " + this.product.description + " Quantities";
        this.showDialogue = true;
    }

    async setUpContractSizingInfo(): Promise<void> {
        await this.loadContractLines();
        await this.loadSizeOptionGroups();

        this.contractLines.forEach(cl => {
            let option = new OrderItemOption();
            
            option.size = cl.size;
            option.contractQuantity = cl.quantity;
            option.orderQuantity = cl.quantity;
            option.description = cl.description,
            option.colour = cl.colour;
            option.sku = cl.sku;
            option.barcode = cl.barcode;
            option.sizeSelected = true;
            option.extension.supplierRef = cl.extension.supplierRef;
            option.extension.description = cl.extension.description;
            option.extension.productGroup = cl.extension.productGroup;

            this.orderItemOptions.push(option);
        });
    }

    private reset() {
        this.selectedRangeID = utils.emptyGuidValue;
        this.selectedTypeID = utils.emptyGuidValue;

        utils.resetObject(this.orderItem);
        utils.resetObject(this.product);
        this.orderItem.orderID = this.orderID;
        this.orderItemOptions = [];
        this.ranges = [];
        this.types = [];
        this.products = [];
        this.countriesOfOrigin = [];

        this.productForm?.resetValidation();
    }

    async viewProductImages(): Promise<void> {
        if (this.product.imageID == utils.emptyGuidValue || this.product.imageID == null) {
            toastr.warning("Image is not available.");
        } else {
            const dialog: ImagePreviewDialogue = this.$refs.imagePreviewDialogue as ImagePreviewDialogue;
            dialog.open(this.product.imageID);
        }
    }

    resetOrderQuantities(): void {
        if (this.product.isContract) {
            this.orderItemOptions.forEach(option => {
                option.orderQuantity = option.contractQuantity;
            });
        } else {
            this.orderItemOptions.forEach(option => {
                if (option.sizeSelected) {
                    option.orderQuantity = this.product.minOrderQuantity;
                } else {
                    option.orderQuantity = 0;
                }
            });
        }
    }

    setMinimumQuantities(): void {
        if (this.product.isContract) {
            this.orderItemOptions.forEach(option => {
                if (this.product.minOrderQuantity >= option.contractQuantity) {
                    option.orderQuantity = this.product.minOrderQuantity;
                } else {
                    option.orderQuantity = Math.ceil(option.orderQuantity / this.product.quantityMultipleOf) * this.product.quantityMultipleOf;
                }
            });
        }
    }

    orderItemSetMinimum(option: OrderItemOption): void {
        if (this.product.minOrderQuantity >= option.contractQuantity) {
            option.orderQuantity = this.product.minOrderQuantity;
        } else {
            option.orderQuantity = Math.ceil(option.orderQuantity / this.product.quantityMultipleOf) * this.product.quantityMultipleOf;
        }
    }

    orderItemAddMultiple(option: OrderItemOption): void {
        option.orderQuantity = +option.orderQuantity + +this.product.quantityMultipleOf;
    }

    orderItemTimes2(option: OrderItemOption): void {
        option.orderQuantity = +option.orderQuantity * 2;
    }

    orderItemAddTenPercent(option: OrderItemOption): void {
        option.orderQuantity = +option.orderQuantity + (Math.ceil(option.orderQuantity / 10));
    }

    orderItemResetQuantity(option: OrderItemOption): void {
        if (this.product.isContract) {
            option.orderQuantity = option.contractQuantity;
        } else {
            option.orderQuantity = this.product.minOrderQuantity;
        }
    }

    setStockSizeOptionQuantity(option: OrderItemOption): void {
        if (option.sizeSelected) {
            option.orderQuantity = this.product.minOrderQuantity;
        } else {
            option.orderQuantity = 0;
        }
    }

    private async save() {
        if (!this.product.isContract && !this.product.requiresNonContractSizing && !this.stockItemForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        } else if (!this.product.isContract && this.product.requiresNonContractSizing && !this.stockItemSizingForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        } else if (this.product.isContract && !this.contractItemForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const postData = {
            orderItem: this.orderItem,
            orderOptions: this.orderItemOptions
        }

        const response: ISaveResponse = await apiClient.post("/api/order/saveOrderItem", postData, "save-order-item");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh", this.orderItem.orderID);
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
    }

    private async loadProductRange() {
        this.ranges = [];
        const response = await apiClient.get("/api/product/productRanges");
        this.ranges.push(...response);
    }

    private async loadProductTypes() {
        this.types = [];
        const response = await apiClient.get("/api/product/productTypes");
        this.types.push(...response);
    }

    private async loadProducts() {
        this.products = [];
        this.orderItemOptions = [];
        utils.resetObject(this.orderItem);
        const response = await apiClient.get(`/api/product/loadOrderProducts?orderID=${this.orderID}&typeID=${this.selectedTypeID}&rangeID=${this.selectedRangeID}`);
        this.products.push(...response.map((p: IProductVM) => new ProductVM(p)));
    }

    private async loadContractLines() {
        this.contractLines = [];
        const response = await apiClient.get(`/api/contract/loadLines?orderID=${this.orderItem.orderID}`);
        this.contractLines.push(...response.map((l: IContractLine) => new ContractLine(l)));
    }

    private async loadSizeOptionGroups() {
        this.orderItemOptions = [];
        this.sizeOptionGroups = [];

        const response = await apiClient.get(`/api/size/loadGroupsByProduct?productID=${this.product.id}`);
        this.sizeOptionGroups.push(...response.map((g: ISizeOptionGroup) => new SizeOptionGroup(g)));

        if (this.sizeOptionGroups.length == 1 && this.product.isContract) {
            this.orderItem.artworkTypeSizeGroupID == this.sizeOptionGroups[0].id;
        } else if (!this.product.isContract) {
            let sizes: Array<SizeOption> = [];
            const response = await apiClient.get(`/api/size/loadSizeGroupSizeOptions?groupID=${this.sizeOptionGroups[0].id}`);
            sizes.push(...response.map((s: ISizeOption) => new SizeOption(s)));

            sizes.forEach(s => {
                let option = new OrderItemOption();
                option.size = s.contractSize;
                if (this.product.userSpecifiedSizeOptions) {
                    option.orderQuantity = 0;
                } else {
                    option.sizeSelected = true;
                    option.orderQuantity = this.product.minOrderQuantity;
                }
                this.orderItemOptions.push(option);
            });
        } else {
            this.orderItem.artworkTypeSizeGroupID == utils.emptyGuidValue;
        }
    }

    private async loadCoos() {
        this.countriesOfOrigin = [];
        const response = await apiClient.get("/api/country/countries");
        this.countriesOfOrigin.push(...response);
    }
}
