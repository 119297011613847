import { IOrderItemOption, OrderItemOption } from "../../OrderItemOption";
import { IOrderItem, OrderItem } from "../../OrderItem";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemVM {
    orderItem: IOrderItem;
    orderOptions: Array<IOrderItemOption>;
    productDescription: string;
    requiresCareLabel: boolean;
    hasCareLabelInfo: boolean;
    careLabelConfirmed: boolean;
    countryOfOrigin: string;
}

export class OrderItemVM implements IOrderItemVM {

    constructor(data?: IOrderItemVM) {
        if (data) this.update(data);
    }

    update(data: IOrderItemVM): void {
        mapData(data, { 
            root: () => this,
            orderItem: () => new OrderItem(),
            orderOptions: () => new OrderItemOption()
        });
    }

    orderItem: OrderItem = new OrderItem();
    orderOptions: Array<OrderItemOption> = [];
    productDescription: string = "";
    requiresCareLabel: boolean = false;
    hasCareLabelInfo: boolean = false;
    careLabelConfirmed: boolean = false;
    countryOfOrigin: string = "";

    get productTitle(): string {
        if (this.countryOfOrigin == null || this.countryOfOrigin == "") {
            return this.productDescription;
        } else {
            return this.productDescription + " - (" + this.countryOfOrigin + ")";
        }
    }
}