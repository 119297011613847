import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IWashcareSymbolCode {
    id: number;
    groupID: number;
    fontCode: string;
    description: string;
    archived: boolean;
    createdDate: Date;
    createdByUserID: string;
    lastUpdatedDate: Date;
    lastUpdatedByUserID: string;
}

export class WashcareSymbolCode implements IWashcareSymbolCode {

    constructor(data?: IWashcareSymbolCode) {
        if (data) this.update(data);
    }

    update(data: IWashcareSymbolCode): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    groupID: number = 0;
    fontCode: string = "";
    description: string = "";
    archived: boolean = false;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastUpdatedDate: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: string = utils.emptyGuidValue;
}
