import utils from "@/utilities/Utils";
import { IOrder, Order } from "../../Order";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderVM {
    order: IOrder;
    createdBy: string;
    confirmedBy: string;
    poNumber: string;
    country: string;
    hasArtworx: boolean;
    rfdReason: string;
}

export class OrderVM implements IOrderVM {

    constructor(data?: IOrderVM) {
        if (data) this.update(data);
    }

    update(data: IOrderVM): void {
        mapData(data, { 
            root: () => this,
            order: () => new Order()
        });
    }

    order: Order = new Order();
    createdBy: string = "";
    confirmedBy: string = "";
    poNumber: string = "";
    country: string = "";
    hasArtworx: boolean = false;
    rfdReason: string = "";

    get isConfirmed(): boolean {
        return utils.hasDateValue(this.order.confirmedDate);
    }

    get rdfSet(): boolean {
        return utils.hasDateValue(this.order.rfdDate);
    }

    get isDispatched(): boolean {
        return utils.hasDateValue(this.order.dispatchedDate);
    }
}