import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemWcComponent {
    id: number;
    orderItemWashcareID: number;
    componentID: string;
    materialID: string;
    percentage: number;
}

export class OrderItemWcComponent implements IOrderItemWcComponent {

    constructor(data?: IOrderItemWcComponent) {
        if (data) this.update(data);
    }

    update(data: IOrderItemWcComponent): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    orderItemWashcareID: number = 0;
    componentID: string = utils.emptyGuidValue;
    materialID: string = utils.emptyGuidValue;
    percentage: number = 0;
    
    materialDescription: string = "";
}
