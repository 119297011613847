import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemOptionExtension {
    orderItemOptionID: number;
    supplierRef: string;
    description: string;
    productGroup: string;
}

export class OrderItemOptionExtension implements IOrderItemOptionExtension {

    constructor(data?: IOrderItemOptionExtension) {
        if (data) this.update(data);
    }

    update(data: IOrderItemOptionExtension): void {
        mapData(data, { root: () => this });
    }

    orderItemOptionID: number = 0;
    supplierRef: string = "";
    description: string = "";
    productGroup: string = "";
}
