import { IOrderItemOptionExtension, OrderItemOptionExtension } from "@/model/OrderItemOptionExtension";
import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemOption {
    id: number;
    orderItemID: number;
    sizeID: string;
    size: string;
    contractQuantity: number;
    orderQuantity: number;
    description: string;
    colour: string;
    sku: string;
    barcode: string;
    createdDate: Date;
    createdByUserID: string;
    extension: IOrderItemOptionExtension;
}

export class OrderItemOption implements IOrderItemOption {

    constructor(data?: IOrderItemOption) {
        if (data) this.update(data);
    }

    update(data: IOrderItemOption): void {
        mapData(data, { 
            root: () => this,
            extension: () => new OrderItemOptionExtension()
        });
    }

    id: number = 0;
    orderItemID: number = 0;
    sizeID: string = utils.emptyGuidValue;
    size: string = "";
    contractQuantity: number = 0;
    orderQuantity: number = 0;
    description: string = "";
    colour: string = "";
    sku: string = "";
    barcode: string = "";
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    extension: OrderItemOptionExtension = new OrderItemOptionExtension();
    
    sizeSelected: boolean = false;
}
