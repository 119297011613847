






















import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import Utils from "@/utilities/Utils";

@Component({ components: { 
        ApiButton
    } 
})

export default class ImagePreviewDialogue extends Vue {

    //
    // -- properties
    //

    private showDialogue: boolean = false;
    imageUrl: string = "";

    //
    // -- general
    //

    async open(imageID: string): Promise<void> {
        this.imageUrl = Utils.isEmptyId(imageID) ? "" : apiClient.resolveUrl(`/api/document/downloadDocument?id=${imageID}`);
        this.showDialogue = true;
    }
}
