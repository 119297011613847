





























import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import ApiButton from "@/vue/components/ApiButton.vue";
import DatePicker from "@/vue/components/DatePicker.vue"
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ 
    components: { 
        ApiButton,
        DatePicker
    } 
})

export default class OrderDispatchDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("dispatchForm") private readonly dispatchForm!: VForm;  

    private showDialogue: boolean = false;
    orderIDs: Array<number> = [];
    dispatchedDate: Date = new Date();

    //
    // -- methods
    //

    async open(ids: Array<number>): Promise<void> {
        this.reset();
        this.orderIDs = ids;
        this.showDialogue = true;
    }

    private reset() {
        this.orderIDs = [];
        this.dispatchForm?.resetValidation();
    }

    private async save() {
        if (!this.dispatchForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const postData = {
            orderIDs: this.orderIDs,
            dispatchedDate: this.dispatchedDate
        }

        const response = await apiClient.post("/api/order/saveDispatchDate", postData, "save-dispatch");
        
        toastr.success("Saved");
        this.$emit("loadOrder", this.orderIDs[0]);
        this.$emit("refreshSearch");
        this.showDialogue = false;
    }
}
