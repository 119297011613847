































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import utils from "@/utilities/Utils";
import { OrderItemWashcareOverviewVM } from "@/model/Api/VM/OrderItemWashcareOverviewVM";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        ConfirmDialogue
    } 
})

export default class CareLabelConfirmDialogue extends Vue {

    //
    // -- properties
    //

    private ApiClient = apiClient;
    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private orderID: number = 0;
    private orderItemID: number = 0;
    private isReview: boolean = false;

    private washcare: OrderItemWashcareOverviewVM = new OrderItemWashcareOverviewVM();

    //
    // -- computed
    //

    get careContentInput(): boolean {
        let retVal = true;

        if (this.washcare.washcareCode1 != '') {
            retVal = false;
        } else if (this.washcare.washcareCode2 != '') {
            retVal = false;
        } else if (this.washcare.washcareCode3 != '') {
            retVal = false;
        } else if (this.washcare.washcareCode4 != '') {
            retVal = false;
        } else if (this.washcare.washcareCode5 != '') {
            retVal = false;
        }

        return retVal;
    }

    get confirmDisabled(): boolean {
        let retVal = false;

        if (this.careContentInput) {
            retVal = true;
        }
        if (this.washcare.components.length == 0) {
            retVal = true;
        }
        if (this.washcare.additionalCares.length == 0) {
            retVal = true;
        }
        if (this.washcare.countryOfOrigin == '') {
            retVal = true;
        }

        return retVal;
    }

    //
    // -- general
    //

    async open(orderItemID: number, orderID: number, isReview: boolean): Promise<void> {
        this.reset();

        this.isReview = isReview;
        if (isReview) {
            this.dialogueTitle = "Review Care Label";
        } else {
            this.dialogueTitle = "Confirm Care Label";
        }
        await this.loadOrderItemWashcare(orderItemID);
        this.orderItemID = orderItemID;
        this.orderID = orderID;

        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.washcare);
    }

    private async confirm() {
        var response = await apiClient.post("/api/order/confirmOrderItemWashcare", this.orderItemID, "order-item-washcare-confirm");
        toastr.success("Confirmed");
        this.$emit("refresh", this.orderID);
        this.showDialogue = false;
    }

    async loadOrderItemWashcare(orderItemID: number): Promise<void> {
        const response = await apiClient.get(`/api/order/loadOrderItemWashcareOverview?ID=${orderItemID}`);
        this.washcare.update(response);

        // weird loading bug causing issues on images
        if (this.washcare.washcareCode1 == null) this.washcare.washcareCode1 = '';
        if (this.washcare.washcareCode2 == null) this.washcare.washcareCode2 = '';
        if (this.washcare.washcareCode3 == null) this.washcare.washcareCode3 = '';
        if (this.washcare.washcareCode4 == null) this.washcare.washcareCode4 = '';
        if (this.washcare.washcareCode5 == null) this.washcare.washcareCode5 = '';
    }
}
