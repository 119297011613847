import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemWcAdditionalCare {
    id: number;
    orderItemWashcareID: number;
    washcareID: string;
}

export class OrderItemWcAdditionalCare implements IOrderItemWcAdditionalCare {

    constructor(data?: IOrderItemWcAdditionalCare) {
        if (data) this.update(data);
    }

    update(data: IOrderItemWcAdditionalCare): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    orderItemWashcareID: number = 0;
    washcareID: string = utils.emptyGuidValue;
}
