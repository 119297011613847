





























































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import utils from "@/utilities/Utils";
import { Country } from "@/model/Country";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import { IWashcare, Washcare } from "@/model/Washcare";
import { IWashcareSymbolCode, WashcareSymbolCode } from "@/model/WashcareSymbolCode";
import { OrderItemWashcare } from "@/model/OrderItemWashcare";
import { OrderItemWcAdditionalCare } from "@/model/OrderItemWcAdditionalCare";
import { OrderItemWcComponent } from "@/model/OrderItemWcComponent";
import { OrderItemWcComponentVM, IOrderItemWcComponentVM } from "@/model/Api/VM/OrderItemWcComponentVM";
import { Watch } from "vue-property-decorator";
import * as toastr from "toastr";

@Component({ components: { 
        ApiButton,
        ConfirmDialogue
    } 
})

export default class CareLabelDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("compositionForm") private readonly cooForm!: VForm;
    @Ref("compositionForm") private readonly compositionForm!: VForm;

    private ApiClient = apiClient;
    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private selectedTab: string = "careContent";
    private isEdit: boolean = false;
    private orderID: number = 0;

    private washcare: OrderItemWashcare = new OrderItemWashcare();

    private washCareCodes1: Array<WashcareSymbolCode> = [];
    private washCareCodes2: Array<WashcareSymbolCode> = [];
    private washCareCodes3: Array<WashcareSymbolCode> = [];
    private washCareCodes4: Array<WashcareSymbolCode> = [];
    private washCareCodes5: Array<WashcareSymbolCode> = [];

    private availableComponents: Array<Washcare> = [];
    private materials: Array<Washcare> = [];
    private currentComponentID: string = utils.emptyGuidValue;
    private currentComponentDoesNotRequireMaterialBreakdown: boolean = false;
    private currentComponent: Array<OrderItemWcComponent> = [];
    private components: Array<OrderItemWcComponentVM> = [];

    private additionalCareSearchString: string = "";
    private availableAdditionalCare: Array<Washcare> = [];
    private selectedAdditionalCare: Array<Washcare> = [];
    private additionalCare: Array<OrderItemWcAdditionalCare> = [];

    private coos: Array<Country> = [];

    //
    // -- computed
    //

    get careContentInput(): boolean {
        let retVal = false;

        if (this.washcare.washcareCode1 != '') {
            retVal = true;
        } else if (this.washcare.washcareCode2 != '') {
            retVal = true;
        } else if (this.washcare.washcareCode3 != '') {
            retVal = true;
        } else if (this.washcare.washcareCode4 != '') {
            retVal = true;
        } else if (this.washcare.washcareCode5 != '') {
            retVal = true;
        }

        return retVal;
    }

    get percentageComponentConfirmVisible(): boolean {
        if (this.currentComponentDoesNotRequireMaterialBreakdown) {
            if (this.currentComponent[0].materialID !== '00000000-0000-0000-0000-000000000000' && this.currentComponent[0].materialID !== undefined) {
                return true;
            } else return false;
        } else return false;
    }

    get componentConfirmVisible(): boolean {
        let totalPercentage = 0;

        this.currentComponent.forEach(m => {
            totalPercentage = +totalPercentage + +m.percentage;
        });

        return totalPercentage == 100;
    }

    get componentOver100Visible(): boolean {
        let totalPercentage = 0;

        this.currentComponent.forEach(m => {
            totalPercentage = +totalPercentage + +m.percentage;
        });

        return totalPercentage > 100;
    }
    
    //
    // -- Watchers
    //

    @Watch("currentComponentID")
    private onCurrentComponentIDChange() {
        if (this.currentComponentID != undefined && this.currentComponentID != '00000000-0000-0000-0000-000000000000') {
            var componentInUse = false;

            this.components.forEach(c => {
                if (c.materials[0].componentID == this.currentComponentID && this.currentComponentID != utils.emptyGuidValue) {
                    toastr.warning("This component has already been added to the Composition", "Cannot Add");
                    this.currentComponentDoesNotRequireMaterialBreakdown = false;
                    this.currentComponent.forEach(c => {
                        c.componentID = '00000000-0000-0000-0000-000000000000';
                    });
                    this.currentComponentID = '00000000-0000-0000-0000-000000000000';
                    componentInUse = true;
                    return;
                }
            });

            if (!componentInUse) {
                this.currentComponent.forEach(c => {
                    c.componentID = this.currentComponentID;
                });
                this.availableComponents.forEach(c => {
                    if (c.id == this.currentComponentID) {
                        this.currentComponentDoesNotRequireMaterialBreakdown = c.doesNotRequireMaterialBreakdown;
                    }
                });
            } else {
                this.currentComponentDoesNotRequireMaterialBreakdown = false;
                this.currentComponent.forEach(c => {
                    c.componentID = '00000000-0000-0000-0000-000000000000';
                });
                this.currentComponentID = '00000000-0000-0000-0000-000000000000';
            }
        } else if (this.currentComponentID == undefined) {
            this.currentComponent.forEach(c => {
                c.componentID = utils.emptyGuidValue;
            });
            this.currentComponentID = utils.emptyGuidValue;
        }
    }

    @Watch("washcare.countryOfOriginID")
    private onCountryIDChanged() {
        if (this.washcare.countryOfOriginID == undefined) {
            this.washcare.countryOfOriginID = "";
        }
    }

    @Watch("additionalCareSearchString")
    private onAdditionalCareSearchStringChanged() {
        if (this.additionalCareSearchString == undefined) {
            this.additionalCareSearchString = "";
            return;
        }

        this.additionalCareSearch();
    }

    //
    // -- general
    //

    async open(orderItemID: number, orderID: number): Promise<void> {
        this.reset();
        this.dialogueTitle = "Care Label Info";
        this.selectedTab = "careContent";
        this.isEdit = false;

        await this.loadWashCareCodes();
        await this.loadWashcareTranslations();
        await this.loadCoos();

        await this.loadOrderItemWashcare(orderItemID);

        this.currentComponent.push(new OrderItemWcComponent());

        this.washcare.orderItemID = orderItemID;
        this.orderID = orderID;

        this.showDialogue = true;
    }

    async edit(orderItemID: number, orderID: number): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Care Label Info";
        this.selectedTab = "careContent";
        this.isEdit = true;

        await this.loadWashCareCodes();
        await this.loadWashcareTranslations();
        await this.loadCoos();

        await this.loadOrderItemWashcare(orderItemID);

        this.currentComponent.push(new OrderItemWcComponent());

        this.washcare.orderItemID = orderItemID;
        this.orderID = orderID;

        this.showDialogue = true;
    }

    previousTab(): void {
        if (this.selectedTab == "coo") this.selectedTab = "additionalCare";
        else if (this.selectedTab == "additionalCare") this.selectedTab = "composition";
        else if (this.selectedTab == "composition") this.selectedTab = "careContent";
        else if (this.selectedTab == "careContent") return;
    }

    nextTab(): void {
        if (this.selectedTab == "careContent") this.selectedTab = "composition";
        else if (this.selectedTab == "composition") this.selectedTab = "additionalCare";
        else if (this.selectedTab == "additionalCare") this.selectedTab = "coo";
        else if (this.selectedTab == "coo") return;
    }

    private reset() {
        utils.resetObject(this.washcare);
        this.washCareCodes1 = [];
        this.washCareCodes2 = [];
        this.washCareCodes3 = [];
        this.washCareCodes4 = [];
        this.washCareCodes5 = [];
        this.availableComponents = [];
        this.materials = [];
        this.currentComponentID = utils.emptyGuidValue;
        this.currentComponentDoesNotRequireMaterialBreakdown = false;
        this.currentComponent = [];
        this.components = [];
        this.availableAdditionalCare = [];
        this.selectedAdditionalCare = [];
        this.additionalCare = [];
        this.coos = [];
    }

    private async save() {
        const postData = {
            washcare: this.washcare,
            components: this.components,
            additionalCare: this.additionalCare
        }

        const response: ISaveResponse = await apiClient.post("/api/order/saveOrderItemWashcare", postData, "order-item-washcare-save");
        if (response.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh", this.orderID);
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
        
    }

    async loadOrderItemWashcare(orderItemID: number): Promise<void> {
        const response = await apiClient.get(`/api/order/loadOrderItemWashcare?ID=${orderItemID}`);
        this.washcare.update(response.washcare);
        // weird loading bug causing issues on images
        if (this.washcare.washcareCode1 == null) this.washcare.washcareCode1 = '';
        if (this.washcare.washcareCode2 == null) this.washcare.washcareCode2 = '';
        if (this.washcare.washcareCode3 == null) this.washcare.washcareCode3 = '';
        if (this.washcare.washcareCode4 == null) this.washcare.washcareCode4 = '';
        if (this.washcare.washcareCode5 == null) this.washcare.washcareCode5 = '';
        this.selectedAdditionalCare.push(...response.additionalCare.map((w: IWashcare) => new Washcare(w)));
        this.components.push(...response.components.map((c: IOrderItemWcComponentVM) => new OrderItemWcComponentVM(c)));

        this.selectedAdditionalCare.forEach(ac => {
            let additionalCare = new OrderItemWcAdditionalCare();
            additionalCare.washcareID = ac.id;
            this.additionalCare.push(additionalCare);

            var index = -1

            for (var i = 0; i < this.availableAdditionalCare.length; i++) {
                if(this.availableAdditionalCare[i].id === ac.id) {
                    index = i;
                    break;
                }
            }

            this.availableAdditionalCare.splice(index, 1);
        });
    }

    async additionalCareSearch(): Promise<void> {
        this.availableAdditionalCare = [];

        const response = await apiClient.get(`/api/translation/additionalCareSearch?searchString=${this.additionalCareSearchString}`);
        this.availableAdditionalCare.push(...response.map((w: IWashcare) => new Washcare(w)));

        this.selectedAdditionalCare.forEach(ac => {
            let additionalCare = new OrderItemWcAdditionalCare();
            additionalCare.washcareID = ac.id;
            this.additionalCare.push(additionalCare);

            for (var i = 0; i < this.availableAdditionalCare.length; i++) {
                if (this.availableAdditionalCare[i].id === ac.id) {
                    this.availableAdditionalCare.splice(i, 1);
                    break;
                }
            }
        });
    }

    async loadWashCareCodes(): Promise<void> {
        this.washCareCodes1 = [];
        this.washCareCodes2 = [];
        this.washCareCodes3 = [];
        this.washCareCodes4 = [];
        this.washCareCodes5 = [];

        const response = await apiClient.get("/api/translation/washcareSymbolCodes");

        this.washCareCodes1.push(...response.group1.map((c: IWashcareSymbolCode) => new WashcareSymbolCode(c)));
        this.washCareCodes2.push(...response.group2.map((c: IWashcareSymbolCode) => new WashcareSymbolCode(c)));
        this.washCareCodes3.push(...response.group3.map((c: IWashcareSymbolCode) => new WashcareSymbolCode(c)));
        this.washCareCodes4.push(...response.group4.map((c: IWashcareSymbolCode) => new WashcareSymbolCode(c)));
        this.washCareCodes5.push(...response.group5.map((c: IWashcareSymbolCode) => new WashcareSymbolCode(c)));
    }

    async loadWashcareTranslations(): Promise<void> {
        this.availableAdditionalCare = [];
        this.availableComponents = [];
        this.materials = [];

        const response = await apiClient.get("/api/translation/careLabelTranslations");

        this.availableAdditionalCare.push(...response.additionalCare.map((w: IWashcare) => new Washcare(w)));
        this.availableComponents.push(...response.components.map((w: IWashcare) => new Washcare(w)));
        this.materials.push(...response.materials.map((w: IWashcare) => new Washcare(w)));
    }

    async loadCoos(): Promise<void> {
        this.coos = [];
        const response = await apiClient.get("/api/country/countries");
        this.coos.push(...response);
    }

    //
    // -- care content methods
    //

    setCareContentSymbol(groupID: number, character: string): void {
        if (groupID == 1) {
            this.washcare.washcareCode1 = character;
        } else if (groupID == 2) {
            this.washcare.washcareCode2 = character;
        } else if (groupID == 3) {
            this.washcare.washcareCode3 = character;
        } else if (groupID == 4) {
            this.washcare.washcareCode4 = character;
        } else if (groupID == 5) {
            this.washcare.washcareCode5 = character;
        }
    }

    //
    // -- componsition methods
    //

    addComponent(): void {
        if (this.currentComponent[0].componentID == '' || this.currentComponent[0].componentID == '00000000-0000-0000-0000-000000000000') {
            var isValid = true;
            
            this.components.forEach(c => {
                if (c.materials[0].componentID == '' || c.materials[0].componentID == '00000000-0000-0000-0000-000000000000') {
                    isValid = false;
                }
            });

            if (!isValid) {
                toastr.warning("You have already created one 'no component' composition", "Cannot Add");
                return;
            }
        }

        if (!this.compositionForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Add Component");
            return;
        }

        var comp = new OrderItemWcComponentVM();
        comp.displayOrder = this.components.length + 1;

        var index = 0;
        this.currentComponent.forEach(cm => {
            index++;

            this.materials.forEach(m => {
                if (cm.materialID == m.id) {
                    var material = new OrderItemWcComponent();
                    material.orderItemWashcareID = cm.orderItemWashcareID;
                    material.componentID = cm.componentID;
                    material.materialID = cm.materialID;
                    material.percentage = cm.percentage;
                    material.materialDescription = m.description;

                    comp.materials.push(material);
                }
            })
        })

        this.availableComponents.forEach(c => {
            if (c.id == comp.materials[0].componentID) {
                comp.componentDescription = c.description;
            }
        });

        this.components.push(comp);
        this.currentComponentID = '00000000-0000-0000-0000-000000000000';

        this.currentComponent = [];
        this.currentComponent.push(new OrderItemWcComponent());

        this.compositionForm.resetValidation();
    }

    deleteComponent(component: OrderItemWcComponentVM): void {
        // indexOf / findIndex doesn't seem to want to work...
        var index = -1

        for (var i = 0; i < this.components.length; i++) {
            if (this.components[i].materials[0].componentID === component.materials[0].componentID) {
                index = i;
                break;
            }
        }

        this.components.splice(index, 1);
    }

    moveComponentUp(displayOrder: number): void {
        this.components.forEach(c => {
            if (displayOrder == 1) {
                return;
            } else if (c.displayOrder == displayOrder) {
                c.displayOrder --;
            } else if (c.displayOrder == displayOrder - 1) {
                c.displayOrder ++;
            }
        });

        this.components.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    moveComponentDown(displayOrder: number): void {
        this.components.forEach(c => {
            if (displayOrder == this.components.length) {
                return;
            } else if (c.displayOrder == displayOrder) {
                c.displayOrder ++;
            } else if (c.displayOrder == displayOrder + 1) {
                c.displayOrder --;
            }
        });

        this.components.sort((a, b) => a.displayOrder - b.displayOrder);
    }

    addMaterial(): void {
        var component = new OrderItemWcComponent();
        component.orderItemWashcareID = this.currentComponent[0].orderItemWashcareID;
        component.componentID = this.currentComponent[0].componentID;

        this.currentComponent.push(component);
    }

    removeMaterial(materialID: string): void {
        // clear object down so there is always at least 1 empty material object available 
        if (this.currentComponent.length == 1) {
            utils.resetObject(this.currentComponent[0]);
            return;
        }

        // indexOf / findIndex doesn't seem to want to work...
        var index = -1

        for (var i = 0; i < this.currentComponent.length; i++) {
            if (this.currentComponent[i].materialID === materialID) {
                index = i;
                break;
            }
        }

        this.currentComponent.splice(index, 1);
    }

    //
    // -- additional care methods
    //

    addAdditionalCare(ac: Washcare): void {
        this.selectedAdditionalCare.push(ac);

        let additionalCare = new OrderItemWcAdditionalCare();
        additionalCare.washcareID = ac.id;
        this.additionalCare.push(additionalCare);

        var index = -1

        for (var i = 0; i < this.availableAdditionalCare.length; i++) {
            if(this.availableAdditionalCare[i].id === ac.id) {
                index = i;
                break;
            }
        }

        this.availableAdditionalCare.splice(index, 1);
    }

    removeAdditionalCare(ac: Washcare): void {
        this.availableAdditionalCare.push(ac);
        this.availableAdditionalCare.sort((a, b) => a.description.localeCompare(b.description));

        var index = -1
        for (var i = 0; i < this.selectedAdditionalCare.length; i++) {
            if(this.selectedAdditionalCare[i].id === ac.id) {
                index = i;
                break;
            }
        }
        this.selectedAdditionalCare.splice(index, 1);

        index = -1
        for (i = 0; i < this.additionalCare.length; i++) {
            if(this.additionalCare[i].washcareID === ac.id) {
                index = i;
                break;
            }
        }
        this.additionalCare.splice(index, 1);
    }
}
