import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemWashcare {
    id: number;
    orderItemID: number;
    countryOfOriginID: string;
    washcareCode1: string;
    washcareCode2: string;
    washcareCode3: string;
    washcareCode4: string;
    washcareCode5: string;
    confirmedDate: Date;
    confirmedByUserID: string;
}

export class OrderItemWashcare implements IOrderItemWashcare {

    constructor(data?: IOrderItemWashcare) {
        if (data) this.update(data);
    }

    update(data: IOrderItemWashcare): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    orderItemID: number = 0;
    countryOfOriginID: string = utils.emptyGuidValue;
    washcareCode1: string = "";
    washcareCode2: string = "";
    washcareCode3: string = "";
    washcareCode4: string = "";
    washcareCode5: string = "";
    confirmedDate: Date = new Date(utils.emptyDateValue);
    confirmedByUserID: string = utils.emptyGuidValue;
}
