













































































































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import ConfirmDialogue from "@/vue/components/dialogues/ConfirmDialogue.vue";
import CareLabelConfirmDialogue from "@/vue/components/dialogues/CareLabelConfirmDialogue.vue";
import CareLabelDialogue from "@/vue/components/dialogues/CareLabelDialogue.vue";
import OrderDialogue from "@/vue/components/dialogues/OrderDialogue.vue";
import OrderDispatchDialogue from "@/vue/components/dialogues/OrderDispatchDialogue.vue";
import OrderProductDialogue from "@/vue/components/dialogues/OrderProductDialogue.vue";
import OrderReadyForDispatchDialogue from "@/vue/components/dialogues/OrderReadyForDispatchDialogue.vue";
import store from "@/store/store";
import utils from "@/utilities/Utils";
import { OrderItem } from "@/model/OrderItem";
import { OrderVM, IOrderVM } from "@/model/Api/VM/OrderVM";
import { IOrderItemVM, OrderItemVM } from "@/model/Api/VM/OrderItemVM";
import { UserRole } from "@/model/Enums";
import * as toastr from "toastr";

@Component({
    components: { 
        ApiButton,
        ConfirmDialogue,
        CareLabelConfirmDialogue,
        CareLabelDialogue,
        OrderDialogue,
        OrderDispatchDialogue,
        OrderProductDialogue,
        OrderReadyForDispatchDialogue
    }
})
export default class Order extends Vue {

    async mounted(): Promise<void> { 
        this.orderID = Number(this.$router.currentRoute.params.orderID);
        await this.load(this.orderID);
        await this.loadOrderProducts(this.orderID);
    }

    //
    // -- properties
    //

    orderID = 0;
    itemToDeleteID = 0;
    washcareToUnconfirm = 0;
    private order: OrderVM = new OrderVM();
    private stockItems: Array<OrderItemVM> = [];
    private stockSizeItems: Array<OrderItemVM> = [];
    private contractItems: Array<OrderItemVM> = [];

    //
    // -- computed
    //

    private get isAdmin(): boolean {
        return store.state.signedInUser?.role == UserRole.SuperAdmin || store.state.signedInUser?.role == UserRole.Admin;
    }

    private get isRetailer(): boolean {
        return store.state.signedInUser?.role == UserRole.Retailer
    }

    get productCount(): number {
        return this.stockItems.length + this.stockSizeItems.length + this.contractItems.length;
    }

    get careLabelsCompleted(): boolean {
        let retVal = true;
        
        this.stockItems.forEach(i => {
            if (i.requiresCareLabel && !i.careLabelConfirmed) {
                retVal = false;
            }
        });

        this.stockSizeItems.forEach(i => {
            if (i.requiresCareLabel && !i.careLabelConfirmed) {
                retVal = false;
            }
        });

        this.contractItems.forEach(i => {
            if (i.requiresCareLabel && !i.careLabelConfirmed) {
                retVal = false;
            }
        });

        return retVal;
    }

    get completedStatus(): string {
        if (this.order.isConfirmed) {
            return "Confirmed";
        } else {
            return "Unconfirmed";
        }
    }

    get completeRequirements(): string {
        if (!this.order.isConfirmed && this.productCount == 0) {
            return "Add Products";
        } else if (!this.order.isConfirmed && !this.careLabelsCompleted) {
            return "Confirm Care Labels";
        } else return "";
    }

    //
    // -- methods
    //

    async load(id: number): Promise<void> {
        const orderData: IOrderVM = await apiClient.get(`api/order/LoadDetail?id=${id}`);
        this.order.update(orderData);
    }

    async loadOrderProducts(id: number): Promise<void> {
        this.stockItems = [];
        this.stockSizeItems = [];
        this.contractItems = [];

        const response = await apiClient.get(`api/order/loadOrderProducts?id=${id}`);
        
        this.stockItems.push(...response.stockItems.map((i: IOrderItemVM) => new OrderItemVM(i)));
        this.stockSizeItems.push(...response.stockSizeItems.map((i: IOrderItemVM) => new OrderItemVM(i)));
        this.contractItems.push(...response.contractItems.map((i: IOrderItemVM) => new OrderItemVM(i)));
    }

    back(): void {
        this.$router.push("/orders/");
    }

    editOrder(): void {
        const dialog: OrderDialogue = this.$refs.orderDialogue as OrderDialogue;
        dialog.edit(this.order.order.id, this.order.order.contractID);
    }

    deleteOrder(): void {
        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doOrderDelete(): Promise<void> {
        await apiClient.post("/api/order/delete", this.order.order.id, "order-delete");
        toastr.warning("Order Deleted");

        const dialog: ConfirmDialogue = this.$refs.deleteDialogue as ConfirmDialogue;
        dialog.hide;

        this.$router.push("/orders/");
    }

    async downloadOrder(): Promise<void> {
        const blob: Blob = await apiClient.get(`/api/order/downloadOrder?id=${this.order.order.id}&contractID=${this.order.order.contractID}`);
        utils.downloadBlob(document, blob, `Order${this.order.order.id}.zip`);
    }

    async resendOrderToCurrentUser(): Promise<void> {
        const response = await apiClient.get(`/api/order/resendOrderToCurrentUser?id=${this.order.order.id}&contractID=${this.order.order.contractID}`);

        if (!response.isSuccess) {
            toastr.error(response.message)
        } else {
            toastr.success("Order Sent");
            this.load(this.order.order.id);
        }
    }

    confirmOrder(): void {
        const dialog: ConfirmDialogue = this.$refs.confirmDialogue as ConfirmDialogue;
        dialog.show();
    }

    async doConfirmOrder(): Promise<void> {
        const response = await apiClient.get(`/api/order/confirm?id=${this.order.order.id}&contractID=${this.order.order.contractID}`);

        if (!response.isSuccess) {
            toastr.error(response.message);
            this.load(this.order.order.id);
        } else {
            toastr.success("Order Confirmed");
            this.load(this.order.order.id);
        }
    }

    unconfirmOrder(): void {
        const dialog: ConfirmDialogue = this.$refs.unconfirmDialogue as ConfirmDialogue;
        dialog.show();
    }

    async doUnconfirmOrder(): Promise<void> {
        await apiClient.get(`/api/order/unconfirm?id=${this.order.order.id}`);
        toastr.success("Order Unconfirmed");
        this.load(this.order.order.id);
    }

    setReadyToDispatchDate(): void {
        var orderIDs: Array<number> = [];
        orderIDs.push(this.order.order.id)

        const dialog: OrderReadyForDispatchDialogue = this.$refs.orderRfdDialogue as OrderReadyForDispatchDialogue;
        dialog.open(orderIDs);
    }

    setDispatchDate(): void {
        var orderIDs: Array<number> = [];
        orderIDs.push(this.order.order.id)

        const dialog: OrderDispatchDialogue = this.$refs.orderDispatchDialogue as OrderDispatchDialogue;
        dialog.open(orderIDs);
    }

    async reviewArtwork(): Promise<void> {
        const blob: Blob = await apiClient.get(`/api/order/Artworx?id=${this.order.order.id}`);
        utils.downloadBlob(document, blob, `Artwork_${this.order.order.id}.pdf`);
    }

    addProduct(): void {
        const dialog: OrderProductDialogue = this.$refs.orderProductDialogue as OrderProductDialogue;
        dialog.open(this.order.order.id);
    }

    editProduct(orderItem: OrderItem): void {
        const dialog: OrderProductDialogue = this.$refs.orderProductDialogue as OrderProductDialogue;
        dialog.edit(orderItem.orderID, orderItem.id);
    }

    editCareLabel(id: number): void {
        const dialog: CareLabelDialogue = this.$refs.careLabelDialogue as CareLabelDialogue;
        dialog.edit(id, this.order.order.id);
    }

    viewCareLabel(id: number): void {
        const dialog: CareLabelConfirmDialogue = this.$refs.careLabelConfirmDialogue as CareLabelConfirmDialogue;
        dialog.open(id, this.order.order.id, true);
    }

    confirmCareLabel(id: number): void {
        const dialog: CareLabelConfirmDialogue = this.$refs.careLabelConfirmDialogue as CareLabelConfirmDialogue;
        dialog.open(id, this.order.order.id, false);
    }
    
    unconfirmWashcare(orderItemID: number): void {
        this.washcareToUnconfirm = orderItemID;
        const dialog: ConfirmDialogue = this.$refs.unconfirmWashcareDialogue as ConfirmDialogue;
        dialog.show();
    }

    async doUnconfirmWashcare(): Promise<void> {
        await apiClient.post("/api/order/unconfirmOrderItemWashcare", this.washcareToUnconfirm, "unconfirm-washcare");
        toastr.success("Washcare Unconfirmed");
        this.loadOrderProducts(this.order.order.id);
    }

    deleteOrderItem(id: number): void {
        this.itemToDeleteID = id;
        const dialog: ConfirmDialogue = this.$refs.deleteItemDialogue as ConfirmDialogue;
        dialog.show();
    }

    private async doOrderItemDelete() {
        await apiClient.post("/api/order/deleteOrderItem", this.itemToDeleteID, "order-item-delete");
        toastr.warning("Product Deleted");

        const dialog: ConfirmDialogue = this.$refs.deleteItemDialogue as ConfirmDialogue;
        dialog.hide;
        
        this.loadOrderProducts(this.order.order.id);
    }

}
