import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IProduct {
    id: string;
    productRangeID: number;
    productTypeID: number;
    description: string;
    ref: string;
    retailerRef: string;
    imageID: string;
    isContract: boolean;
    requiresNonContractSizing: boolean;
    requiresWashcare: boolean;
    userSpecifiedSizeOptions: boolean;
    requiresCoo: boolean;
    allowDuplicateProduct: boolean;
    quantityMultipleOf: number;
    estimatedDispatchDays: number;
    minOrderQuantity: number;
    isHidden: boolean;
    created: Date;
    createdByUserID: string;
    lastUpdated: Date;
    lastUpdatedByUserID: string;
}

export class Product implements IProduct {

    constructor(data?: IProduct) {
        if (data) this.update(data);
    }

    update(data: IProduct): void {
        mapData(data, { root: () => this });
    }

    id: string = utils.emptyGuidValue;
    productRangeID: number = 0;
    productTypeID: number = 0;
    description: string = "";
    ref: string = "";
    retailerRef: string = "";
    imageID: string = utils.emptyGuidValue;
    isContract: boolean = false;
    requiresNonContractSizing: boolean = false;
    requiresWashcare: boolean = false;
    userSpecifiedSizeOptions: boolean = false;
    requiresCoo: boolean = false;
    allowDuplicateProduct: boolean = false;
    quantityMultipleOf: number = 0;
    estimatedDispatchDays: number = 0;
    minOrderQuantity: number = 0;
    isHidden: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: string = utils.emptyGuidValue;
}
