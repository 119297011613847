






































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import ApiButton from "@/vue/components/ApiButton.vue";
import DatePicker from "@/vue/components/DatePicker.vue"
import { ILookupItem, LookupItem } from "@/model/LookupItem";
import { Ref } from "vue-property-decorator";
import { LookupGroup } from "@/model/Enums";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ 
    components: { 
        ApiButton,
        DatePicker
    } 
})

export default class OrderReadyForDispatchDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("rfdForm") private readonly rfdForm!: VForm;  

    private showDialogue: boolean = false;
    orderIDs: Array<number> = [];
    rfdDate: Date = new Date();
    rfdReasonID: number = 0;

    //private order: Order = new Order();
    private reasons: Array<ILookupItem> = [];

    //
    // -- methods
    //

    async open(ids: Array<number>): Promise<void> {
        this.reset();
        this.loadReasons();
        this.orderIDs = ids;
        this.showDialogue = true;
    }

    private reset() {
        this.orderIDs = [];
        this.reasons = [];
        this.rfdForm?.resetValidation();
    }

    private async save() {
        if (!this.rfdForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const postData = {
            orderIDs: this.orderIDs,
            rfdDate: this.rfdDate,
            rfdReasonID: this.rfdReasonID
        }

        const response = await apiClient.post("/api/order/saveRfdDate", postData, "save-rfd");
        toastr.success("Saved");

        this.$emit("loadOrder", this.orderIDs[0]);
        this.$emit("refreshSearch");
        this.showDialogue = false;
    }

    private async loadReasons() {
        this.reasons = [];
        const response = await apiClient.get(`/api/admin/loadLookups?id=${LookupGroup.ReadyForDispatchReason}&includeArchived=${false}`);
        this.reasons.push(...response.map((i: ILookupItem) => new LookupItem(i)));
    }
}
