import { IProduct, Product } from "../../Product";
import { mapData } from "@/utilities/DataMapping";

export interface IProductVM {
    product: IProduct;
    inUse: boolean;
}

export class ProductVM implements IProductVM {

    constructor(data?: IProductVM) {
        if (data) this.update(data);
    }

    update(data: IProductVM): void {
        mapData(data, { 
            root: () => this,
            product: () => new Product()
        });
    }

    product: Product = new Product();
    inUse: boolean = false;

    get productDescription(): string {
        return this.product.ref + " - " + this.product.description;
    }
}
