import {IOrderItemWcComponentVM, OrderItemWcComponentVM } from "./OrderItemWcComponentVM";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemWashcareOverviewVM {
    washcareCode1: string;
    washcareCode2: string;
    washcareCode3: string;
    washcareCode4: string;
    washcareCode5: string;
    countryOfOrigin: string;
    components: Array<IOrderItemWcComponentVM>;
    additionalCares: Array<string>;
}

export class OrderItemWashcareOverviewVM implements IOrderItemWashcareOverviewVM {

    constructor(data?: IOrderItemWashcareOverviewVM) {
        if (data) this.update(data);
    }

    update(data: IOrderItemWashcareOverviewVM): void {
        mapData(data, { 
            root: () => this,
            components: () => new OrderItemWcComponentVM()
        });
    }

    washcareCode1: string = "";
    washcareCode2: string = "";
    washcareCode3: string = "";
    washcareCode4: string = "";
    washcareCode5: string = "";
    countryOfOrigin: string = "";
    components: Array<OrderItemWcComponentVM> = [];
    additionalCares: Array<string> = [];
}
