import utils from "@/utilities/Utils";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItem {
    id: number;
    orderID: number;
    productID: string;
    artworkTypeSizeGroupID: string;
    countryOfOriginID: string;
    createdDate: Date;
    createdByUserID: string;
}

export class OrderItem implements IOrderItem {

    constructor(data?: IOrderItem) {
        if (data) this.update(data);
    }

    update(data: IOrderItem): void {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    orderID: number = 0;
    productID: string = utils.emptyGuidValue;
    artworkTypeSizeGroupID: string = utils.emptyGuidValue;
    countryOfOriginID: string = utils.emptyGuidValue;
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: string = utils.emptyGuidValue;
}
