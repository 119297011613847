import { IOrderItemWcComponent, OrderItemWcComponent } from "../../OrderItemWcComponent";
import { mapData } from "@/utilities/DataMapping";

export interface IOrderItemWcComponentVM {
    materials: Array<IOrderItemWcComponent>;
    componentDescription: string;

    displayOrder: number;
}

export class OrderItemWcComponentVM implements IOrderItemWcComponentVM {

    constructor(data?: IOrderItemWcComponentVM) {
        if (data) this.update(data);
    }

    update(data: IOrderItemWcComponentVM): void {
        mapData(data, { 
            root: () => this,
            materials: () => new OrderItemWcComponent()
        });
    }

    materials: Array<OrderItemWcComponent> = [];
    componentDescription: string = "";

    displayOrder: number = 0;
}